import axios from "axios";
const currentUrl = window.location.href;
let url = "";

// Verifica Qual base está ativo
if (currentUrl.includes("boraverapp.com")) {
  url = "https://api.boraverapp.com";
} else if (currentUrl.includes("devaribox.co")) {
  url = "https://boraverapp-api.devaribox.co/";
} else if (currentUrl.includes(":300")) {
  url = "https://boraverapp-api.devaribox.co/";
  // url = "https://api.boraverapp.com";
  // url = "http://192.168.0.26:8000";
}

export const baseURL = url;
export default axios.create({
  baseURL: baseURL,
});
