import React, { useCallback, useEffect, useRef, useState } from "react";
import { DivRow, Card, DivColumn, Content, Table, TableRow, TableHead, TableBody } from "./styles";
import { Container, Loading } from "../Solicitations/styles";
import {
  CampanhaClient,
  DeleteCampaign,
  EditCampaign,
} from "../../services/cliente/client";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";
import Modal from "../../components/Modal/Modal";
import { toast } from "react-toastify";
import ImgUser from "../../assets/user.webp";
import { DivBetween, DivImage, Title } from "../VitrineCreators/styles";
import InputSearch from "../../components/InputSearch";
import { formatDate, formatUTCDate } from "../../utils/helpers";
import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DivCenter } from "../ListCreators/styles";
import { FaArrowLeft, FaArrowRight, FaEdit } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import ModalEditorCampaign from "./ModalEditorCampaign";
import ModalDeleteCampaign from "./ModalDeleteCampaign";

const MyCampaign = () => {
  const { auth } = useAuth();
  const { userData, setUserData, clientId } = useClient();
  const history = useHistory();
  const page = useRef(1);
  const totalPages = useRef();
  const [campanhas, setCampanhas] = useState([]);
  const [loadingCampanhas, setLoadingCampanha] = useState(false);
  const [loadingDeleteCampanha, setLoadingDeleteCampanha] = useState(false);
  const [loadingEditCampanha, setLoadingEditCampanha] = useState(false);
  const [titleModalCampanha, setTitleModalCampanha] = useState("");
  const [modalVisibleDeleteCampanha, setModalVisibleDeleteCampanha] =
    useState(false);
  const [modalVisibleEditCampanha, setModalVisibleEditPachage] = useState(false);
  const [payload, setPayload] = useState({
    cliente: clientId,
    nome: "",
    valor: 0,
    descricao: "",
  });
  

  useEffect(() => {
    if (clientId) loadCampanhas();
  }, []);

  const loadCampanhas = async (search, _page = 1) => {
    try {
      setLoadingCampanha(true);
      const status = "Nova Oferta,Em Andamento,Pago Aguardando Serviço,Concluído"

      const result = await CampanhaClient(clientId, status, auth, search, _page);

      if (result) {
        totalPages.current = result.num_pages;
        page.current = _page
        setCampanhas(result.results);
        setLoadingCampanha(false);
      }
    } catch (err) {
      setLoadingCampanha(false);
      console.error("Erro: ", err);
    }
  }

  const activeModalCampanha = (title, campanha) => {
    if (campanha) {
      setPayload({
        ...payload,
        id: campanha.id,
        nome: campanha.nome_campanha,
        valor: campanha.valor,
        descricao: campanha.descricao,
        data_inicio: campanha.data_inicio ? campanha.data_inicio.slice(0, 10) : '',
        quantidade_vagas: campanha.quantidade_vagas,
        data_termino: campanha.data_termino ? campanha.data_termino.slice(0,10) : ''
      });
    } else {
      const newPayload = {
        cliente: clientId,
        nome: "",
        valor: 0,
        data_inicio: "",
        quantidade_vagas: 0,
        data_termino: ""
      };
      setPayload(newPayload);
    }
    setTitleModalCampanha(title);
    if (title === "Apagar Campanha") {
      setModalVisibleDeleteCampanha(true);
    } else {
      setModalVisibleEditPachage(true);
    }
  };

  const submitCampanha = async () => {
    if (validate()) {
      setLoadingEditCampanha(true);
      try {
        if (payload.id) {
          let _payload = {
            nome: payload.nome,
            valor: payload.valor,
            descricao: payload.descricao,
            estados: payload.estados,
            data_inicio: payload.data_inicio,
            data_termino: payload.data_termino
          };

          const result = await EditCampaign(payload.id, _payload, auth);
          if (result) {
            toast.success("Campanha editada com sucesso!");
            setLoadingEditCampanha(false);
            setModalVisibleEditPachage(false);
            let _package = campanhas.map((pacote) => {
              if (pacote.id === result.id) {
                return {
                  ...pacote,
                  nome: result.nome,
                  valor: result.valor,
                  descricao: result.descricao,
                  estados: result.estados,
                  data_inicio: result.data_inicio,
                  data_termino: result.data_termino
                };
              } else {
                return pacote;
              }
            });
            setCampanhas(_package);
          }
        }
      } catch (error) {
        setLoadingEditCampanha(false);
        setModalVisibleEditPachage(false);
        console.error("Erro: ", error);
      }
    }
  };

  const validate = () => {
    if (!payload.nome) {
      toast.error("Digite o nome do pacote");
      return false;
    }
    if (!payload.valor) {
      toast.error("Digite o valor do pacote");
      return false;
    }
    if (!payload.descricao) {
      toast.error("Digite a descrição do pacote");
      return false;
    }
    return true;
  };

  const deleteCampanha = async () => {
    if (payload.id) {
      try {
        setLoadingDeleteCampanha(true);
        const result = await DeleteCampaign(payload.id, auth);
        if (result) {
          let _package = campanhas.filter((pacote) => pacote.id !== payload.id);

          setCampanhas(_package);
          toast.success("Campanha excluída");
          setLoadingDeleteCampanha(false);
          setModalVisibleDeleteCampanha(false);
        }
      } catch (error) {
        setLoadingDeleteCampanha(false);
        console.error("Erro: ", error);
      }
    }
  };

  const handlePayload = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };

  const handleNavigate = (item) => {
    history.push({pathname: "detail-campaign", state: item });
  }

  const handlePagination = (_page) => {
    if (_page < 1 || _page > totalPages.current) return;

    loadCampanhas(null, _page);
  }

  return (
    <Content style={{ justifyContent: "center" }}>
      <DivColumn>
        <DivBetween style={{marginBottom: 14}}>
          <Title>Minhas Campanhas</Title>
        </DivBetween>
        <InputSearch
          bgColor={"var(--white)"}
          name="search"
          placeholder="Pesquisar nome da campanha"
          submit={loadCampanhas}
        />
        <DivRow style={{paddingBottom: 20, overflowX: "auto"}}>
          <Table cellspacing="0" hasData={campanhas.length > 0}>
            <TableRow>
              <TableHead>Nome da Campanha</TableHead>
              <TableHead>Inscritos</TableHead>
              <TableHead>Vagas</TableHead>
              <TableHead>Aprovados</TableHead>
              <TableHead>Início - Fim</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Ações</TableHead>
            </TableRow>
            <TableBody>
              {campanhas.length ? campanhas.map((campanha) => (
                <TableRow>
                  <td align="center" className="name" onClick={() => handleNavigate(campanha)}>{campanha.nome_campanha}</td>
                  <td align="center"  onClick={() => handleNavigate(campanha)}>{campanha?.quantidade_candidaturas || 0}</td>
                  <td align="center"  onClick={() => handleNavigate(campanha)}>{campanha?.quantidade_vagas}</td>
                  <td align="center"  onClick={() => handleNavigate(campanha)}>{campanha?.aceite_influencers.length}</td>
                  <td align="center"  onClick={() => handleNavigate(campanha)}>{campanha?.data_inicio && formatDate(campanha.data_inicio)} - {campanha?.data_termino && formatDate(campanha.data_termino)}</td>
                  <td align="center"  onClick={() => handleNavigate(campanha)}>
                    <button className={campanha.status.replace(" ", "")}>
                      {campanha?.status}
                    </button>
                  </td>
                  <td>
                    <DivRow style={{ flexWrap: "nowrap", marginBottom: 10, gap: 8 }}>
                      <div
                        className="action"
                        title="Editar"
                        onClick={() => activeModalCampanha("Editar Campanha", campanha)}
                      >
                        <FaEdit />
                      </div>
                      <div
                        className="action"
                        title="Excluir"
                        onClick={() => activeModalCampanha("Apagar Campanha", campanha)}
                      >
                        <IoMdClose color="var(--red)" />
                      </div>
                    </DivRow>
                  </td>
                </TableRow>
              )) : null}
            </TableBody>
          </Table>

          {campanhas.length === 0 && !loadingCampanhas ? (
            <DivRow className="justify-center">
              <span style={{ fontSize: 24, fontWeight: 600 }}>Sem Candidaturas</span>
            </DivRow>
          ) : null}
          {/* {campanhas.length ? campanhas.map((campanha) => (
            <Card key={campanha.id}>
              <h2>{campanha.nome_campanha}</h2>
              <p>Publicada: {campanha.criado_em ? formatDate(campanha.criado_em) : ''}</p>
              <DivRow style={{marginBottom: 6}}>
                <p style={{marginBottom: 6, width: '50%'}}>
                  <span>Valor:</span> R$ {campanha.valor}
                </p>
                <p style={{marginBottom: 6, width: '50%'}}>
                  <span>Início:</span> {campanha.data_inicio ? formatUTCDate(campanha.data_inicio) : ''}
                </p>
              </DivRow>
              <DivRow>
                <p style={{marginBottom: 6, width: '50%'}}>
                  <span>Total de vagas:</span> {campanha.quantidade_vagas}
                </p>
                <p style={{marginBottom: 6, width: '50%'}}>
                  <span>Encerramento:</span> {campanha.data_termino ? formatUTCDate(campanha.data_termino) : ''}
                </p>
              </DivRow>
              <DivRow className="justify-end" style={{alignItems: 'center', marginBottom: 8, padding: 8}}>
                <Button
                  setBackgroundColor={'var(--brightpurple)'}
                  setColor={'var(--white)'}
                  setShadow={'var(--brightpurple)'}
                  style={{marginBottom: 0}}
                  onClick={() => activeModalCampanha("Editar Campanha", campanha)}
                >
                  Editar
                </Button>
                <Button
                  setBackgroundColor={'var(--red)'}
                  setColor={'var(--white)'}
                  setShadow={'var(--red)'}
                  style={{marginBottom: 0}}
                  onClick={() => activeModalCampanha("Apagar Campanha", campanha)}
                >
                  Excluir
                </Button>
              </DivRow>
            </Card>
          )) : null} */}
        </DivRow>
        <DivCenter style={{marginBottom: 10}}>
            <Button
              setBackgroundColor={'var(--white)'}
              setColor={'var(--back)'}
              disabled={loadingCampanhas || page.current === 1}
              onClick={() => handlePagination(page.current - 1)}
              >
              <FaArrowLeft size={10}  />{" "}
              Anterior
            </Button>
            <Button
              setBackgroundColor={'var(--white)'}
              setColor={'var(--back)'}
              disabled={loadingCampanhas || page.current === totalPages.current}
              onClick={() => handlePagination(page.current + 1)}
            >
              Próximo{" "}
              <FaArrowRight size={10}  />
            </Button>
          </DivCenter>
        {loadingCampanhas ? <Spinner /> : <div style={{ height: 80 }}></div>}
      </DivColumn>

      {/* Editar Campanha */}
      {modalVisibleEditCampanha ? (
        <ModalEditorCampaign 
          titleModalCampanha={titleModalCampanha}
          payload={payload}
          loadingEditCampanha={loadingEditCampanha}
          handlePayload={handlePayload}
          submitCampanha={submitCampanha}
          setModalVisibleEditPachage={setModalVisibleEditPachage}
        />
      ) : null}

      {/* Apagar Campanha  */}
      {modalVisibleDeleteCampanha ? (
        <ModalDeleteCampaign 
          titleModalCampanha={titleModalCampanha}
          loadingDeleteCampanha={loadingDeleteCampanha}
          deleteCampanha={deleteCampanha}
          setModalVisibleDeleteCampanha={setModalVisibleDeleteCampanha}
        />
      ) : null}
    </Content>
  );
};
export default MyCampaign;
