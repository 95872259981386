import styled from "styled-components";

export const Card = styled.div`
  border: 3px solid var(--black);
  border-radius: 25px;
  cursor: pointer;
  margin-inline: 8px;
  margin-bottom: 20px;
  min-height: 350px;
  height: auto;
  width: 260px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--yellow);
  height: 60px;
  width: 100%;
`;

export const Title = styled.p`
  font-family: Inconsolata;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
`;

export const Body = styled.div`
  height: 170px;
  overflow: hidden;
  width: 100%;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  height: auto;
  width: 100%;
`;

export const Creator = styled.h6`
  font-family: Archivo;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
`;

export const Segmento = styled.p`
  color: var(--yellow);
  font-family: Inconsolata;
  font-size: 18px;
  font-weight: 600;
  margin-inline: auto;
  max-width: 90%;
  text-align: center;
`;
