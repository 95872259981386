import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Container,
  Main,
  Content,
  Solicitations,
  DivRow,
  DivColumn,
  Card,
  DivButton,
} from "./styles";
import { useClient } from "../../providers/user";
import { useAuth } from "../../providers/auth";
import api from "../../services";
import Logo from "../../assets/user.webp";
import { formatDate, onlyNumber } from "../../utils/helpers";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { toReal } from "../../utils/currency";
import { alterStatusCampanha, alterStatusSolicitation } from "../../providers/solicitation";
import { toast } from "react-toastify";
import { DivBetween, DivImage, Title } from "../VitrineCreators/styles";
import ImgUser from "../../assets/user.webp";
import InputSearch from "../../components/InputSearch";
import { FaTiktok, FaInstagram } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { useDebounce } from "../../hooks/useDebounce";
import RenderSolicitation from "./RenderSolicitation";
import CardSolicitation from "./CardSolicitation";

const SolicitationsList = () => {
  const { auth } = useAuth();
  const { userData, setUserData, clientId } = useClient();
  const pageSolicitations = useRef(1);
  const pageCandidaturas = useRef(1);
  const pageSolicitationsFinished = useRef(1);
  const pageCandidaturasCompleted = useRef(1);
  const totalPagesSolicitations = useRef();
  const totalPagesCandidaturas = useRef();
  const totalPagesSolicitationsFinished = useRef();
  const totalPagesCandidaturasCompleted = useRef();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [visibleSidebar, setVisibleSidebar] = useState(true);
  const [showSolicitations, setShowSolicitations] = useState(1);
  const [newSolicitations, setNewSolicitations] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [solicitationsAccepted, setSolicitationsAccepted] = useState([]);
  const [candidaturas, setCandidaturas] = useState([]);
  const [solicitationsFinished, setSolicitationsFinished] = useState([]);
  const [solicitationsRefused, setSolicitationsRefused] = useState([]);
  const [filterSolicitations, setFilterSolicitations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [sectionActive, setSectionActive] = useState("Em Andamento");
  const [solicitationSelected, setSolicitationSelected] = useState();
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [mySolicitation, setMySolicitation] = useState(false);
  const [solicitationConcluded, setSolicitationConcluded] = useState(false);
  const [payload, setPayload] = useState({
    cliente: clientId,
    nome: "",
    valor: 0,
    descricao: "",
  });

  useEffect(() => {
    if (clientId) {
      setSolicitationsAccepted([]);
      setSolicitationsFinished([]);
      getSolicitationsAccepted();
      getCandidaturas();

      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [searchValue, showSolicitations, clientId]);

  // useEffect(() => {
  //     getSolicitations();
  //     window.addEventListener("scroll", handleScroll);
  // }, [])

  const getSolicitations = async (_page = 1) => {
    const response = await api
      .get(`api/v3/solicitacao_servico_cli/${clientId}/`, {
        params: {
          page: _page,
          status: "Nova Oferta",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((response) => {
        const total =
          response.data.count % 10 === 0
            ? response.data.count / 10
            : Math.floor(response.data.count / 10) + 1;
        // totalPages.current = total;

        if (_page === 1) {
          setNewSolicitations(response.data.results);
          // page.current = 1;
        } else {
          setNewSolicitations((old) => [...old, ...response.data.results]);
          // setPage(page);
          // page.current = _page;
        }
      });

    return response;
  };

  const getSolicitationsAccepted = async (search, _page = 1) => {
    let params = {
      page: _page ? _page : pageSolicitations.current,
      status: showSolicitations === 1 
        ? "Nova Oferta,Em Andamento,Pago Aguardando Serviço" 
        : showSolicitations === 2
        ? "Concluído,recusado"
        : null,
      search: search ? search : searchValue
    };

    const response = await api
      .get(`api/v3/solicitacao_servico_cli/${clientId}/`, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((response) => {
        const total =
          response.data.count % 10 === 0
            ? response.data.count / 10
            : Math.floor(response.data.count / 10) + 1;
        if(showSolicitations === 1) {
          totalPagesSolicitations.current = total;
          pageSolicitations.current = _page;
          setSolicitationsAccepted((prev) => {
            const newData = response.data.results.filter(
              (item) => !prev.some((prevItem) => prevItem.id === item.id)
            );
            return [...prev, ...newData];
          });
        } else if (showSolicitations === 2) {
          totalPagesSolicitationsFinished.current = total;
          pageSolicitationsFinished.current = _page;
          setSolicitationsFinished((prev) => {
            const newData = response.data.results.filter(
              (item) => !prev.some((prevItem) => prevItem.id === item.id)
            );
            return [...prev, ...newData];
          });
        }
          
      });

    return response;
  };

  const getCandidaturas = async (search,   _page = 1) => {
    let params = {
      page: _page ? _page : pageCandidaturas.current,
      id_cliente: clientId,
      status: showSolicitations === 0 ? "0,1" : "2", // Pendente, Aprovado ou Rejeitado
      search: search ? search : searchValue
    };

    const response = await api
    .get(`api/v1/candidaturas/`, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    })
    .then((response) => {
      const total =
        response.data.count % 10 === 0
          ? response.data.count / 10
          : Math.floor(response.data.count / 10) + 1;
      
      if (showSolicitations === 0) {
        totalPagesCandidaturas.current = total;
        pageCandidaturas.current = _page;

        setCandidaturas((prev) => {
          const newData = response.data.results.filter(
            (item) => !prev.some((prevItem) => prevItem.id === item.id)
          );
          return [...prev, ...newData];
        });
      } else if (showSolicitations === 2) {
        const _results = [...response.data.results];
        totalPagesCandidaturasCompleted.current = total;
        pageCandidaturasCompleted.current = _page;

        setSolicitationsFinished((old) => {
          const newData = _results.filter(
            (item) => !old.some((prevItem) => prevItem.id === item.id)
          );
          return [...old, ...newData];
        });
      }
    })

    return response
  }

  const handleScroll = useCallback(
    useDebounce((e) => {
      const isBottomReached =
        window.innerHeight + e.target.documentElement.scrollTop + 1 >=
        e.target.documentElement.scrollHeight;

      if (isBottomReached) {
        // Verifica se está mostrando solicitações ou candidaturas
        if (showSolicitations === 0) {
          if (pageCandidaturas.current < totalPagesCandidaturas.current) {
            getCandidaturas(null, pageCandidaturas.current + 1);
          }
        } else if (showSolicitations === 1) {
          // Verifica se existem mais páginas de solicitações e candidaturas abertas
          if (pageSolicitations.current < totalPagesSolicitations.current) {
            getSolicitationsAccepted(null, pageSolicitations.current + 1);
          }
        } else if (showSolicitations === 2) {
          // Verifica se existem mais páginas de solicitações e candidaturas encerradas
          if (
            pageSolicitationsFinished.current <
            totalPagesSolicitationsFinished.current
          ) {
            getSolicitationsAccepted(null, pageSolicitationsFinished.current + 1);
          }
          if (
            pageCandidaturasCompleted.current <
            totalPagesCandidaturasCompleted.current
          ) {
            getCandidaturas(null, pageCandidaturasCompleted.current + 1);
          }
        }
      }
    }, 300), // 300ms debounce
    [
      showSolicitations,
      pageSolicitations,
      totalPagesSolicitations,
      pageCandidaturas,
      totalPagesCandidaturas,
      pageSolicitationsFinished,
      totalPagesSolicitationsFinished,
      pageCandidaturasCompleted,
      totalPagesCandidaturasCompleted,
    ]
  );

  const handleSearch = (search) => {
    setSearchValue(search);
    if (showSolicitations === 0) {
      setCandidaturas([]);
      getCandidaturas(search, pageCandidaturas.current);
    } else if (showSolicitations === 1) {
      setSolicitationsAccepted([]);
      getSolicitationsAccepted(search, pageSolicitations.current);
    } else {
      setSolicitationsFinished([]);
      getSolicitationsAccepted(search, pageSolicitationsFinished.current);
    }
  }

  const handleSelectSolicitation = (
    solicitation,
    mySolicitation,
    concluded
  ) => {
    setSolicitationSelected(solicitation);
    // setShouldOpenModal(true);
    setMySolicitation(mySolicitation);
    if (concluded) {
      setSolicitationConcluded(true);
    } else {
      setSolicitationConcluded(false);
    }
    if (!solicitation.campanha) {
      setStep(step + 1);
    } else {
      setStep(3);
    }
  };

  const handleAlterStatus = async (status) => {
    setLoadingStatus(true);
    try {
      const result = await alterStatusSolicitation(
        solicitationSelected.id,
        status,
        auth
      );
      if (result) {
        if (status === "Em Andamento") {
          openWhatsApp();
          setStep(1);
        } else if (status === "recusado") {
          setStep(1);
        }
        await getSolicitationsAccepted();
        await getCandidaturas();
        setLoadingStatus(false);
        // setShouldOpenModal(false);
      }
    } catch (error) {
      toast.error(`${error.message}`);
      setLoadingStatus(false);
    }
  };

  const handleAlterStatusCampanha = async (status) => {
    setLoadingStatus(true);
    try {
      let _status;
      if (status === "Em Andamento") {
        _status = 1
      } else if (status === "recusado") {
        _status = 2
      }

      const result = await alterStatusCampanha(
        solicitationSelected.id,
        _status,
        auth
      );
      if (result) {
        if (showSolicitations === 0) {
          const solicitations = candidaturas.map(item => {
            if (item.id === result.id) {
              return { ...item, status: result.status }
            }
            return item
          })
          setCandidaturas(solicitations)
        } else if (showSolicitations === 1) {
          const solicitations = solicitationsAccepted.map(item => {
            if (item.id === result.id) {
              return { ...item, status: result.status }
            }
            return item
          })
          setSolicitationsAccepted(solicitations)
        } else if (showSolicitations === 2) {
          const solicitations = solicitationsFinished.map(item => {
            if (item.id === result.id) {
              return { ...item, status: result.status }
            }
            return item
          })
          setSolicitationsFinished(solicitations)
        }

        toast.success('Salvo com sucesso!')
        setStep(1);
        setLoadingStatus(false);
        // setShouldOpenModal(false);
      }
    } catch (error) {
      toast.error(`${error.message}`);
      setLoadingStatus(false);
    }
  }

  const handlePayload = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };

  function handleDateInicio(value) {
    try {
      return format(new Date(value), "dd/MM/yyyy");
    } catch (error) {
      return value;
    }
  }

  const handleNavigate = (creator) => {
    const url = `/influencerdetails/${creator.id}`;

    // Armazenar o estado no sessionStorage
    sessionStorage.setItem("influencer", JSON.stringify(creator));

    const fullUrl = `${window.location.origin}/#${url}`;
    window.open(fullUrl, "_blank");
  };

  const handlePortifolio = (url) => {
    window.open(url, "_blank");
  }

  const openTikTok = (tiktok) => {
    const _tiktok = tiktok && tiktok[0] === "@" ? tiktok.slice(1) : tiktok
    const url = `https://www.tiktok.com/@${_tiktok}`;
    window.open(url, "_blank");
  };

  const openInstagram = (instagram) => {
    const _instagram = instagram && instagram[0] === "@" ? instagram.slice(1) : instagram
    const url = `https://www.instagram.com/${_instagram}`;
    window.open(url, "_blank");
  };

  const openWhatsApp = () => {
    if (solicitationSelected && solicitationSelected?.influencidor?.whatsapp) {
      const url = `https://wa.me/${onlyNumber(solicitationSelected.influencidor.whatsapp)}`

      window.open(url, "_blank");
    }
  }

  return (
    <Container>
      <Main visibleSidebar={visibleSidebar}>
        <DivBetween style={{ marginBottom: 14 }}>
          <Title>Central de Propostas</Title>
          {/* <DivImage>
            <img
              src={
                userData && userData.profile_picture
                  ? userData.profile_picture
                  : ImgUser
              }
              alt="Imagem do usuário"
              height={60}
              width={60}
            />
          </DivImage> */}
        </DivBetween>
        {step === 1 ? (
          <>
            <InputSearch
              bgColor={"var(--white)"}
              name="search"
              placeholder="Pesquisar nome da proposta"
              submit={handleSearch}
            />
            <Content>
              <div className="solicitation">
                {/* <button
                  className={showSolicitations === 0 ? "active" : ""}
                  onClick={() => {
                    setShowSolicitations(0);
                  }}
                >
                  <h2>Minhas Campanhas</h2>
                </button> */}
                
                <button
                  className={showSolicitations === 1 ? "active" : ""}
                  onClick={() => {
                    setShowSolicitations(1);
                  }}
                >
                  <h2>Ofertas Creators</h2>
                </button>

                <button
                  className={showSolicitations === 2 ? "active" : ""}
                  onClick={() => {
                    setShowSolicitations(2);
                  }}
                >
                  <h2>Histórico</h2>
                </button>
                {loading ? <span className="loader"></span> : null}
              </div>

              {/* {showSolicitations === 1 ? (
                            <div className="solicitationsType">
                                <button
                                    className={sectionActive === "Em Andamento" ? "active" : ""}
                                    onClick={() => {
                                        setSectionActive("Em Andamento");
                                    }}
                                >
                                    <span>Em Andamento</span>
                                </button>
                                <button
                                    className={
                                        sectionActive === "Concluído" ? "active" : ""
                                    }
                                    onClick={() => {
                                        setSectionActive("Concluído");
                                    }}
                                >
                                    <span>Finalizadas</span>
                                </button>
                                <button
                                    className={sectionActive === "recusado" ? "active" : ""}
                                    onClick={() => {
                                        setSectionActive("recusado");
                                    }}
                                >
                                    <span>Recusadas</span>
                                </button>
                            </div>
                        ) : null} */}

              {showSolicitations === 0 ? (
                <Solicitations>
                  {<RenderSolicitation 
                    solicitations={candidaturas}
                    openTikTok={openTikTok} 
                    openInstagram={openInstagram} 
                    handleSelectSolicitation={handleSelectSolicitation}
                  />}
                </Solicitations>
              ) : showSolicitations === 1 ? (
                <Solicitations>
                  {<RenderSolicitation
                    solicitations={solicitationsAccepted} 
                    openTikTok={openTikTok} 
                    openInstagram={openInstagram} 
                    handleSelectSolicitation={handleSelectSolicitation} 
                  />}
                </Solicitations>
              ) : showSolicitations === 2 ? (
                <Solicitations>
                  {<RenderSolicitation
                    solicitations={solicitationsFinished} 
                    openTikTok={openTikTok} 
                    openInstagram={openInstagram} 
                    handleSelectSolicitation={handleSelectSolicitation} 
                  />}
                </Solicitations>
              ) : null}
            </Content>
          </>
        ) : step === 2 && solicitationSelected ? (
          <CardSolicitation 
            mySolicitation={mySolicitation}
            titulo={solicitationSelected.titulo}
            criacao={solicitationSelected.criacao}
            sobre_influencer={solicitationSelected.sobre_influencer}
            valor={solicitationSelected.valor}
            foto={solicitationSelected.influencidor.profile_picture}
            nome={solicitationSelected.influencidor.nomecompleto}
            cidade={solicitationSelected.influencidor.cidade}
            estado={solicitationSelected.influencidor.estado}
            tiktok={solicitationSelected.influencidor.tiktok}
            instagram={solicitationSelected.influencidor.instagram}
            link_portifolio={solicitationSelected.influencidor.link_portifolio}
            whatsapp={solicitationSelected.influencidor.whatsapp}
            status={solicitationSelected.status}
            descricao={solicitationSelected.descricao_servico}
            step={step}
            setStep={setStep}
            openTikTok={openTikTok}
            openInstagram={openInstagram}
            handlePortifolio={handlePortifolio}
            handleAlterStatus={handleAlterStatus}
            loadingStatus={loadingStatus}
          />
        ) : step === 3 && solicitationSelected ? (
          <CardSolicitation 
            mySolicitation={mySolicitation}
            titulo={solicitationSelected?.campanha_detalhes?.nome_campanha}
            criacao={solicitationSelected?.campanha_detalhes?.data_inicio}
            valor={solicitationSelected?.campanha_detalhes?.valor}
            foto={solicitationSelected.influencidor.profile_picture}
            nome={solicitationSelected.influencidor.nomecompleto}
            cidade={solicitationSelected.influencidor.cidade}
            estado={solicitationSelected.influencidor.estado}
            tiktok={solicitationSelected.influencidor.tiktok}
            instagram={solicitationSelected.influencidor.instagram}
            link_portifolio={solicitationSelected.influencidor.link_portifolio}
            whatsapp={solicitationSelected.influencidor.whatsapp}
            status={solicitationSelected.status}
            descricao={solicitationSelected.descricao_servico}
            justificativa={solicitationSelected.justificativa}
            step={step}
            setStep={setStep}
            openTikTok={openTikTok}
            openInstagram={openInstagram}
            handlePortifolio={handlePortifolio}
            handleAlterStatus={handleAlterStatusCampanha}
            loadingStatus={loadingStatus}
          />
        ) : null}
      </Main>
    </Container>
  );
};

export default SolicitationsList;
