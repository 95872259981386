import React from "react";
import Modal from "../../components/Modal/Modal";
import { Container, Loading } from "../Solicitations/styles";
import { DivRow } from "./styles";

const ModalDeleteCampaign = ({
  titleModalCampanha,
  loadingDeleteCampanha,
  setModalVisibleDeleteCampanha,
  deleteCampanha,
}) => {
  return (
    <Modal
      title={titleModalCampanha}
      setShouldOpenModal={() => setModalVisibleDeleteCampanha(false)}
    >
      <Container
        style={{
          height: 200,
          justifyContent: "space-between",
          flexDirection: "column",
          minHeight: "78dvh"
        }}
      >
        <div>
          <h2>Tem certeza que deseja excluir essa campanha?</h2>
          <p
            style={{
              color: "#000",
              marginTop: 10,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            Esta ação é irreversível
          </p>
        </div>
        <DivRow className="justify-center">
          {loadingDeleteCampanha ? <Loading style={{ margin: 10 }} /> : null}
          <button
            className="primary"
            onClick={() => setModalVisibleDeleteCampanha(false)}
            disabled={loadingDeleteCampanha}
          >
            Cancelar
          </button>
          <button
            className="danger"
            onClick={() => deleteCampanha()}
            disabled={loadingDeleteCampanha}
          >
            Apagar
          </button>
        </DivRow>
      </Container>
    </Modal>
  );
};

export default ModalDeleteCampaign;
