import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  /* height: ${({ isAuth }) => (isAuth ? '100dvh' : 'auto')}; */

  main {
    flex-grow: 1;
    /* padding: 1rem; */
    margin-top: ${({ isAuth }) => (isAuth ? '90px' : 0)};
    transition: margin-left 0.2s ease-in-out;
    overflow-x: hidden;

    /* Para telas grandes */
    @media (min-width: 768px) {
      margin-left: ${({ visibleSidebar }) => (visibleSidebar ? '190px' : '0')};
    }
    
    @media (min-width: 990px) {
      margin-left: ${({ visibleSidebar }) => (visibleSidebar ? '18%' : '0')};
    }
    
    /* Para telas pequenas */
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }

  header {
    flex-grow: 1;
    transition: margin-left 0.2s ease-in-out;

    /* Para telas grandes */
    @media (min-width: 768px) {
      margin-left: ${({ visibleSidebar }) => (visibleSidebar ? '18%' : '0')};
    }

    /* Para telas pequenas */
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
`;
