import React, { useEffect, useState } from "react";
import { Card, Container, DivRow, Content, DivStep } from "./styles";
import { DivColumn } from "../MyPackages/styles";
import { Loading } from "../Solicitations/styles";
import { CreateCampaign, FaixaEtaria } from "../../services/cliente/client";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";
import Button from "../../components/Button/Button";
import ImgUser from "../../assets/user.webp";
import { DivBetween, DivImage, Title } from "../VitrineCreators/styles";
import { toReal } from "../../utils/currency";
import { formatDate } from "../../utils/helpers";
import { Estados, Redes_Sociais, Tipo_Remuneracao } from "../../utils/enum";
import { getEstado } from "../../services/estados";
import { Remuneracoes } from "../../services/remuneracao";
import { RedeSocial } from "../../services/redes-sociais";
import { addDays } from "date-fns";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NewCampaign = () => {
  const { auth } = useAuth();
  const { userData, clientId } = useClient();
  const history = useHistory();
  const [loadingCreateCampaign, setLoadingCreateCampaign] = useState(false);
  const [step, setStep] = useState(1);
  const [faixaEtaria, setFaixaEtaria] = useState([]);
  const [estados, setEstados] = useState([]);
  const [remuneracao, setRemuneracao] = useState([]);
  const [redeSocial, setRedeSocial] = useState([]);
  const today = new Date();
  const [payload, setPayload] = useState({
    cliente: clientId,
    nome_campanha: "",
    valor: 0,
    data_inicio: "",
    quantidade_vagas: 5,
    data_termino: "",
    descricao: "",
    apenas_influencers_selecionados: false,
    estados: "",
    faixa_etaria_campanha: '',
    remuneracoes: 1,
    redes_sociais: 1
  });

  useEffect(() => {
    getFaixaEtaria();
    getEstados();
    getRemuneracoes();
    getRedesSociais();
  }, [])

  const getFaixaEtaria = async () => {
    try {
        const result = await FaixaEtaria(auth);
        if (result) {
            const _results = result.results.map((item) => {
                return {
                    value: item.id,
                    label: `Mímino: ${item.idade_minima}, Máximo: ${item.idade_maxima}`
                }
            })

            setFaixaEtaria(_results)
            setPayload({ ...payload, faixa_etaria_campanha: +_results[0]?.value })
        }
    } catch (error) {
        console.error("Erro: ", error);
    }
  }

  const getEstados = async () => {
    try {
      const result = await getEstado(auth)
      if (result) {
        const _results = result.results.map((item) => {
          return {
            id: item.id,
            nome: reverseEstados[item.nome],
            criado_em: item.criado_em
          }
        })

        setEstados(_results);
        setPayload({ ...payload, estados: _results[0]?.id })
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  }

  const getRemuneracoes = async () => {
    try {
      const result = await Remuneracoes(auth)
      if (result) {
        const _results = result.results.map((item) => {
          return {
            id: item.id,
            nome: reverseTipoRemuneracao[item.nome],
            criado_em: item.criado_em
          }
        })

        setRemuneracao(_results);
        setPayload({ ...payload, remuneracoes: _results[0]?.id })
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  }

  const getRedesSociais = async () => {
    try {
      const result = await RedeSocial(auth)
      if (result) {
        const _results = result.results.map((item) => {
          return {
            id: item.id,
            nome: reverseRedeSocial[item.nome],
            criado_em: item.criado_em
          }
        })

        setRedeSocial(_results);
        setPayload({ ...payload, redes_sociais: _results[0]?.id })
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  }

  const submitCampanha = async () => {
    if (validate()) {
      setLoadingCreateCampaign(true);
      try {
        const _payload = {
          cliente: clientId,
          nome_campanha: payload.nome_campanha,
          valor: payload.valor,
          data_inicio: payload.data_inicio,
          quantidade_vagas: payload.quantidade_vagas,
          data_termino: payload.data_termino,
          descricao: payload.descricao,
          apenas_influencers_selecionados: payload.apenas_influencers_selecionados,
          estados: [payload.estados],
          faixa_etaria_campanha: [faixaEtaria[0]?.value],
          remuneracoes: [payload.remuneracoes],
          redes_sociais: [payload.redes_sociais]
        }
        const result = await CreateCampaign(_payload, auth);
        if (result) {
          toast.success("Campanha criada com sucesso!");
          setLoadingCreateCampaign(false);
          setStep(1);
          setPayload({
            ...payload,
            nome: "",
            valor: 0,
            data_inicio: today,
            quantidade_vagas: 0,
            data_termino: addDays(today, 10),
            descricao: "",
            apenas_influencers_selecionados: false,
            estados: 0,
            faixa_etaria_campanha: '',
            remuneracoes: 1,
            redes_sociais: 1
          });
          history.push("/campaign");
        }
      } catch (error) {
        setLoadingCreateCampaign(false);
        console.error("Erro: ", error);
      }
    }
  };

  const validate = () => {
    if (!payload.nome_campanha) {
      toast.error("Digite o nome da campanha");
      return false;
    }
    if (!payload.valor) {
      toast.error("Digite o valor da campanha");
      return false;
    }
    if (!payload.data_inicio) {
      toast.error("Digite a data de início da campanha");
      return false;
    }
    // if (!payload.quantidade_vagas) {
    //   toast.error("Digite a quantidade de vagas da campanha");
    //   return false;
    // }
    if (!payload.data_termino) {
      toast.error("Digite a data de término da campanha");
      return false;
    }
    if (payload.estados === "") {
      toast.error("Selecione o estado");
      return false;
    }
    if (!payload.descricao) {
      toast.error("Digite a descrição da campanha");
      return false;
    }
    return true;
  };

  const handlePayload = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };

  const faixaEtariaSelecionada = (id) => {
    const result = faixaEtaria.find((item) => item.value === id)

    if (result) 
        return result.label
  }

  const estadoSelecionado = (id) => {
    const result = estados.find((item) => item.id === id)

    if (result) 
        return result.nome
  }

  const tipoRemuneracaoSelecionado = (id) => {
    const result = remuneracao.find((item) => item.id === id)

    if (result) 
        return result.nome
  }

  const redeSocialSelecionado = (id) => {
    const result = redeSocial.find((item) => item.id === id)

    if (result) 
        return result.nome
  }

  const reverseEstados = Object.keys(Estados).reduce((acc, key) => {
    acc[Estados[key]] = key;
    return acc;
  }, {});

  const reverseTipoRemuneracao = Object.keys(Tipo_Remuneracao).reduce((acc, key) => {
    acc[Tipo_Remuneracao[key]] = key;
    return acc;
  }, {});

  const reverseRedeSocial = Object.keys(Redes_Sociais).reduce((acc, key) => {
    acc[Redes_Sociais[key]] = key;
    return acc;
  }, {});

  return (
    <Container>
      <DivColumn style={{ margin: "0 auto", width: "90%" }}>
        <DivBetween style={{ marginBottom: 14 }}>
          <Title>Criar Campanhas</Title>
          { /** <DivImage>
            <img
              src={
                userData && userData.profile_picture
                  ? userData.profile_picture
                  : ImgUser
              }
              alt="Imagem do usuário"
              height={60}
              width={60}
            />
          </DivImage> */}
        </DivBetween>
        {step === 1 ? (
          <Card>
            <DivRow style={{ justifyContent: "flex-start" }}>
              <div
                className="step"
                style={{ display: "flex", alignItems: "center", width: "45%" }}
              >
                <DivStep active={step === 1}>1</DivStep> Criação de Campanha
              </div>
              <div
                className="step"
                style={{ display: "flex", alignItems: "center", width: "45%" }}
              >
                <DivStep active={step === 2}>2</DivStep> Visualizar e Publicar
              </div>
            </DivRow>
            <Content
              style={{
                flexDirection: "column",
                marginTop: 10,
                marginInline: "auto",
              }}
            >
              <label htmlFor="nome_campanha">Nome da oferta</label>
              <input
                type="text"
                name="nome_campanha"
                id="nome_campanha"
                value={payload.nome_campanha ? payload.nome_campanha : ""}
                style={{ width: "100%" }}
                onChange={({ target }) =>
                  handlePayload("nome_campanha", target.value)
                }
              />
            </Content>
            <DivRow style={{ marginBottom: 10 }}>
              <Content style={{ width: "48%" }}>
                <label htmlFor="valorServico">Valor</label>
                <input
                  type="number"
                  name="valorServico"
                  id="valorServico"
                  value={payload.valor ? payload.valor : ""}
                  style={{ width: "100%" }}
                  onChange={({ target }) =>
                    handlePayload("valor", target.value)
                  }
                />
              </Content>
              <Content style={{ width: "48%" }}>
                <label htmlFor="data_inicio">Início</label>
                <input
                  type="date"
                  name="data_inicio"
                  id="data_inicio"
                  value={payload.data_inicio ? payload.data_inicio : ""}
                  style={{ width: "100%" }}
                  onChange={({ target }) =>
                    handlePayload("data_inicio", target.value)
                  }
                />
              </Content>
            </DivRow>
            <DivRow style={{ marginBottom: 10 }}>
              {/* <Content style={{ flexDirection: "column", width: "48%" }}>
                <label htmlFor="quantidade_vagas">Total de Vagas</label>
                <input
                  type="number"
                  name="quantidade_vagas"
                  id="quantidade_vagas"
                  min={0}
                  value={payload?.quantidade_vagas || ""}
                  style={{ width: "100%" }}
                  onChange={({ target }) =>
                    handlePayload("quantidade_vagas", target.value)
                  }
                />
              </Content> */}
              <Content style={{flexDirection: "column", width: "48%"}}>
                <label htmlFor="">Estado</label>
                <select
                    value={payload.estados}
                    onChange={({target}) => handlePayload('estados', +target.value)}
                >
                  <option value="" selected disabled>Selecione</option>
                  {estados.map((item) => (
                      <option key={item.id} value={item.id}>
                          {item.nome}
                      </option>
                  ))}
                </select>
              </Content>
              <Content style={{ flexDirection: "column", width: "48%" }}>
                <label htmlFor="data_termino">Término</label>
                <input
                  type="date"
                  name="data_termino"
                  id="data_termino"
                  value={payload.data_termino ? payload.data_termino : ""}
                  style={{ width: "100%" }}
                  onChange={({ target }) =>
                    handlePayload("data_termino", target.value)
                  }
                />
              </Content>
            </DivRow>
            {/* <DivRow style={{ marginBottom: 10 }}>
              <Content style={{ flexDirection: "column" }}>
                <label htmlFor="apenas_influencers_selecionados">
                  Apenas creators selecionado
                </label>
                <label htmlFor="apenas_influencers_selecionados_sim" style={{display: 'flex', alignItems: 'baseline'}}>
                  <input
                    type="radio"
                    id="apenas_influencers_selecionados_sim"
                    name="apenas_influencers_selecionados"
                    checked={payload.apenas_influencers_selecionados === true}
                    onChange={() =>
                      handlePayload(
                        "apenas_influencers_selecionados",
                        true
                      )
                    }
                    style={{width: 30}}
                  />
                  Sim
                </label>
                <label htmlFor="apenas_influencers_selecionados_nao" style={{display: 'flex', alignItems: 'baseline'}}>
                  <input
                    type="radio"
                    id="apenas_influencers_selecionados_nao"
                    name="apenas_influencers_selecionados"
                    checked={payload.apenas_influencers_selecionados === false}
                    onChange={() =>
                      handlePayload(
                        "apenas_influencers_selecionados",
                        false
                      )
                    }
                    style={{width: 30}}
                  />
                  Não
                </label>
              </Content>
            </DivRow> */}
            {/* <DivRow style={{ marginBottom: 10 }}>
                <Content style={{ flexDirection: "column", width: '48%' }}>
                    <label htmlFor="">Faixa etária</label>
                    <select
                        value={payload.faixa_etaria_campanha}
                        onChange={({target}) => handlePayload('faixa_etaria_campanha', target.value)}
                    >
                        {faixaEtaria.map((item) => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </select>
                </Content>
                <Content style={{flexDirection: "column", width: "48%"}}>
                    <label htmlFor="">Estado</label>
                    <select
                        value={payload.estados}
                        onChange={({target}) => handlePayload('estados', +target.value)}
                    >
                        {estados.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.nome}
                            </option>
                        ))}
                    </select>
                </Content>
            </DivRow> */}
            {/* <DivRow style={{ marginBottom: 10 }}>
                <Content style={{flexDirection: "column", width: "48%"}}>
                    <label htmlFor="">Tipo remuneração</label>
                    <select
                        value={payload.remuneracoes}
                        onChange={({target}) => handlePayload('remuneracoes', +target.value)}
                    >
                        {remuneracao.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.nome}
                            </option>
                        ))}
                    </select>
                </Content>
                <Content style={{flexDirection: "column", width: "48%"}}>
                    <label htmlFor="">Rede social</label>
                    <select
                        value={payload.redes_sociais}
                        onChange={({target}) => handlePayload('redes_sociais', +target.value)}
                    >
                        {redeSocial.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.nome}
                            </option>
                        ))}
                    </select>
                </Content>
            </DivRow> */}
            <Content>
              <label htmlFor="descricaoServico">Descrição detalhada</label>
              <textarea
                id="descricaoServico"
                value={payload.descricao ? payload.descricao : ""}
                style={{ width: "100%" }}

                onChange={({ target }) =>
                  handlePayload("descricao", target.value)
                }
              />
            </Content>
            <DivRow
              style={{ justifyContent: "flex-end", marginInline: "auto" }}
            >
              {loadingCreateCampaign ? (
                <Loading style={{ margin: 10 }} />
              ) : null}
              <Button
                type="button"
                setBackgroundColor={"var(--yellow)"}
                disabled={loadingCreateCampaign}
                style={{ width: "100%" }}
                onClick={() => setStep(step + 1)}
              >
                Avançar
              </Button>
            </DivRow>
          </Card>
        ) : (
          <Card>
            <DivRow style={{ justifyContent: "flex-start" }}>
              <div
                className="step"
                style={{ display: "flex", alignItems: "center", width: "45%" }}
              >
                <DivStep active={step === 1}>1</DivStep> Criação de Campanha
              </div>
              <div
                className="step"
                style={{ display: "flex", alignItems: "center", width: "45%" }}
              >
                <DivStep active={step === 2}>2</DivStep> Visualizar e Publicar
              </div>
            </DivRow>
            <Content
              style={{
                flexDirection: "column",
                marginTop: 10,
                marginInline: "auto",
              }}
            >
              <label>Nome da oferta: </label>
              <p>{payload.nome_campanha}</p>

              <DivRow style={{ marginBottom: 10 }}>
                <Content>
                  <label>Valor:</label>
                  <p>{payload.valor ? toReal(+payload.valor) : ""}</p>
                </Content>
                <Content style={{ width: "48%" }}>
                  <label>Data de início:</label>
                  <p>
                    {payload.data_inicio ? formatDate(`${payload.data_inicio}T00:00:00`) : ""}
                  </p>
                </Content>
              </DivRow>

              <DivRow style={{ marginBottom: 20 }}>
                {/* <Content style={{ width: "48%" }}>
                  <label>Quantidade de vagas:</label>
                  <p>{payload.quantidade_vagas}</p>
                </Content> */}
                <Content style={{ width: "48%" }}>
                  <label>Data de término:</label>
                  <p>
                    {payload.data_termino
                      ? formatDate(`${payload.data_termino}T00:00:00`)
                      : ""}
                  </p>
                </Content>
              </DivRow>
              {/* <label>Apenas creators selecionados:</label>
              <p>{payload.apenas_influencers_selecionados ? "SIM" : "NÃO"}</p>

              <label>Faixa etária:</label>
              <p>{faixaEtariaSelecionada(payload.faixa_etaria_campanha)}</p> */}

              <label>Estado:</label>
              <p>{estadoSelecionado(payload.estados)}</p>

              {/* <label>Tipo de remuneração:</label>
              <p>{tipoRemuneracaoSelecionado(payload.remuneracoes)}</p>

              <label>Rede social:</label>
              <p>{redeSocialSelecionado(payload.redes_sociais)}</p> */}

              <label>Descrição detalhada:</label>
              <p>{payload.descricao}</p>
            </Content>
            <DivRow style={{ marginTop: 16 }}>
              <Button
                setBackgroundColor={"var(--yellow)"}
                style={{ width: "48%" }}
                disabled={loadingCreateCampaign}
                onClick={() => submitCampanha()}
              >
                Publicar Campanha
              </Button>
              <Button
                setBackgroundColor={"var(--graycard)"}
                setShadow={"var(--graycard)"}
                disabled={loadingCreateCampaign}
                style={{ width: "48%" }}
                onClick={() => setStep(step - 1)}
              >
                Alterar Campanha
              </Button>
            </DivRow>
          </Card>
        )}
      </DivColumn>
    </Container>
  );
};

export default NewCampaign;
