import React, { useEffect, useRef, useState } from "react";
import { CardCandidature, Content, DivColumn, DivLeft, DivRight, DivRow, Subtitle, Title } from "./styles";
import { TbArrowBackUp } from "react-icons/tb";
import { DivBetween } from "../VitrineCreators/styles";
import { DivCenter } from "../ListCreators/styles";
import Button from "../../components/Button/Button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Spinner from "../../components/Spinner";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import api from "../../services";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";
import { alterStatusCampanha, alterVistoCandidatura } from "../../providers/solicitation";
import { formatDateHour } from "../../utils/helpers";

const Candidatures = () => {
  const { auth } = useAuth();
  const { clientId } = useClient();
  const location = useLocation();
  const history = useHistory();
  const page = useRef(1);
  const totalPages = useRef();
  const [campanha, setCampanha] = useState();
  const [candidaturas, setCandidaturas] = useState([]);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [candidatureSelected, setCandidatureSelected] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (location && location.state) {
      const { state } = location;
      const id = state.id;

      setCampanha(state);
      getCandidaturas(1, id);
    } else {
      history.goBack();
    }
  }, [location]);

  const getCandidaturas = async (_page, id) => {
    try {
      setLoading(true);
      let params = {
        page: _page ? _page : page.current,
        id_cliente: clientId,
        id_campanha: id ? id : campanha.id,
        status: "0,1,2", // Pendente, Aprovado ou Rejeitado
        search: search ? search : searchValue,
      };

      const result = await api
        .get(`api/v1/candidaturas/`, {
          params,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((res) => {
          setLoading(false);
          totalPages.current = res.data.num_pages;
          page.current = params.page;
          setCandidaturas((prev) => [...prev, ...res.data.results]);
        });
    } catch (error) {
      setLoading(false);
      console.error("Erro: ", error);
    }
  };

  const handlePortfolio = async (link, idInfluencer) => {
    try {
      let url = `${link}`;

      let params = {
        influencer: idInfluencer,
        tipo_notificacao: 10,
      };

      window.open(url, "_blank", "noopener,noreferrer");

      if (clientId) {
        params["cliente"] = clientId;
        await api.post("/api/v1/eventos/instagram/", params, {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });
      } else {
        await api.post("/api/v1/eventos/instagram/", params);
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  const handlePagination = (_page) => {
    if (_page < 1 || _page > totalPages.current) return;

    getCandidaturas(_page, null);
  };

  const handleAlterStatusCampanha = async (status) => {
    if(candidatureSelected){
      setLoadingStatus(true);
      try {
        let _status;
        if (status === "aceito") {
          _status = 1;
        } else if (status === "recusado") {
          _status = 2;
        }
  
        const result = await alterStatusCampanha(candidatureSelected.id, _status, auth);
        if (result) {
          const _candidaturas = candidaturas.map((item) => {
            if (item.id === result.id) {
              return { ...item, status: result.status };
            }
            return item;
          });
          const _candidatureSelected = {...candidatureSelected}

          _candidatureSelected.status = result.status

          setCandidaturas(_candidaturas);
          setCandidatureSelected(_candidatureSelected)
  
          toast.success("Salvo com sucesso!");
          setLoadingStatus(false);
        }
      } catch (error) {
        toast.error(`${error.message}`);
        setLoadingStatus(false);
      }
    } 
  };

  const handleVistoCandidatura = async (item) => {
    try {
      const status = item.status

      const payload = {
        id: item.id,
        status:  status === "Pendente" ? 0 : status === "Aprovado" ? 1 : 2,
        visto: true
      }

      const result = await alterVistoCandidatura(payload, auth)

      if (result) {
        const _candidaturas = candidaturas.map((i) => {
          if (i.id === result.id) {
            return { ...i, visto: result.visto }
          }
          return i
        })

        setCandidaturas(_candidaturas)
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  }

  const handleCandidatureSelected = (item) => {
    setCandidatureSelected(item);

    if (!item.visto) {
      handleVistoCandidatura(item)
    }
  }

  return (
    <Content>
      <DivRow
        className="align-center"
        onClick={() => history.goBack()}
        style={{ cursor: "pointer", marginBottom: 10, width: "fit-content" }}
      >
        <TbArrowBackUp size={34} title="Voltar" />
        <p style={{ width: "fit-content" }}>Voltar</p>
      </DivRow>
      <DivBetween style={{ marginTop: 0, marginBottom: 14 }}>
        <Title size={26}>{campanha?.nome_campanha}</Title>
      </DivBetween>
      <DivRow style={{ gap: 14, overflowX: "auto" }}>
        <DivLeft style={{ display: candidatureSelected && window.innerWidth <= 575 ? 'none' : 'block'}}>
          <Subtitle size={20} style={{ marginLeft: 10, marginTop: 8, marginBottom: 8 }}>Candidaturas({candidaturas.length})</Subtitle>

          {candidaturas.map((item) => (
            <CardCandidature active={item?.id === candidatureSelected?.id} visto={item?.visto} key={item?.id} onClick={() => handleCandidatureSelected(item)}>
              <img src={item.influencidor?.profile_picture} />
              <DivColumn>
                <h3 className="title-candidatura">{item.influencidor?.nome}</h3>
                <p className="sobre">{item.influencidor?.sobre}</p>
                <p style={{ marginTop: 6 }}>{item.visto ? "Visualizado" : null}</p>
              </DivColumn>
            </CardCandidature>
          ))}

          <DivRow className="justify-center" style={{ marginBottom: 10 }}>
            <Button
             setBackgroundColor="transparent" 
             setColor="var(--purple-50)" 
             setShadow="transparent" 
             disabled={totalPages.current == page.current} 
             onClick={() => handlePagination(page.current + 1)}
            >Carregar mais</Button>
          </DivRow>
        </DivLeft>
          {candidatureSelected ? (
            <DivRight>
              <button
                onClick={() => setCandidatureSelected(null)}
                style={{ display: window.innerWidth <= 575 ? 'block' : 'none', marginBottom: 10 }}
              >
                Voltar
              </button>
              <DivColumn>
                <DivRow style={{ marginBottom: 20 }}>
                  <img className="profile_picture" src={candidatureSelected?.influencidor?.profile_picture} />
                  <DivColumn style={{ width: "fit-content" }}>
                    <span style={{ fontSize: 22, fontWeight: 600, marginBottom: 6 }}>{candidatureSelected?.influencidor?.nome}</span>
                    <span>{candidatureSelected.influencidor.cidade}
                        {candidatureSelected.influencidor.estado
                          ? ` - ${candidatureSelected.influencidor.estado}`
                          : null}</span>
                    <span style={{ fontSize: 12, marginTop: 4 }}>Candidatura realizada dia {candidatureSelected.data_candidatura ? formatDateHour(candidatureSelected.data_candidatura) : null}</span>
                    <span style={{ marginTop: 4 }}>Status: <span className={`status-candidatura ${candidatureSelected.status}`}>{candidatureSelected.status}</span></span>
                  </DivColumn>
                </DivRow>
                {candidatureSelected.influencidor.sobre ? (
                  <>
                    <h3 style={{ marginBottom: 6 }}>Sobre o creator</h3>
                    <p>{candidatureSelected.influencidor.sobre}</p>
                  </>
                ) : null}

                {candidatureSelected.justificativa ? (
                  <>
                    <h3 style={{ marginTop: 10, marginBottom: 6 }}>Justificativa</h3>
                    <p>{candidatureSelected.justificativa}</p>
                  </>
                ) : null}
                <DivRow className="justify-center" style={{ marginTop: 30, marginBottom: 0 }}>
                  <Button
                  setBackgroundColor="var(--green)"
                  setShadow="var(--green)"
                  disabled={["Aprovado", "Rejeitado"].includes(candidatureSelected?.status)}
                  onClick={() => handleAlterStatusCampanha("aceito")}
                  >Aceitar Candidatura</Button>
                  <Button
                  setBackgroundColor="var(--red)"
                  setColor="var(--white)"
                  setShadow="var(--red)"
                  disabled={["Aprovado", "Rejeitado"].includes(candidatureSelected?.status)}
                  onClick={() => handleAlterStatusCampanha("recusado")}
                  >Recusar Candidatura</Button>
                </DivRow>
              </DivColumn>
            </DivRight>
          ) : null }
        {/* <Table hasData={candidaturas.length > 0}>
          <TableRow>
            <TableHead style={{ textAlign: "left" }}>Inscritos</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Ações</TableHead>
          </TableRow>
          <TableBody>
            {candidaturas.map((item) => (
              <TableRow style={{ cursor: "default" }}>
                <td className="small">
                  <DivRow className="align-center">
                    <img
                      className="profile_picture"
                      src={item.influencidor.profile_picture}
                    />
                    <DivColumn style={{ width: "80%" }}>
                      <p className="nome">
                        {item.influencidor.nome}{" "}
                        <span className="data">
                          {item.data_candidatura
                            ? formatDateHour(item.data_candidatura)
                            : null}
                        </span>
                      </p>
                      <p>{item.influencidor.sobre}</p>
                    </DivColumn>
                  </DivRow>
                </td>
                <td align="center">
                  <button className={item.status}>{item.status}</button>
                </td>
                <td align="center">
                  <DivRow
                    style={{ flexWrap: "nowrap", marginBottom: 10, gap: 8 }}
                  >
                    <div
                      className="portifolio"
                      title="Ver Portfólio"
                      onClick={() =>
                        handlePortfolio(
                          item.influencidor.link_portifolio,
                          item.influencidor.id
                        )
                      }
                    >
                      <FaRegUserCircle size={16} color="var(--lightgray)" />
                      <span>Portfólio</span>
                    </div>
                    <div
                      className={
                        item.status !== "Pendente"
                          ? "action disabled"
                          : "action"
                      }
                      title="Recusar"
                      onClick={() => handleAlterStatusCampanha("recusado")}
                    >
                      <IoMdClose color="var(--red)" />
                    </div>
                    <div
                      className={
                        item.status !== "Pendente"
                          ? "action disabled"
                          : "action"
                      }
                      title="Aceitar"
                      onClick={() => handleAlterStatusCampanha("aceito")}
                    >
                      <FaCheck color="var(--green)" />
                    </div>
                  </DivRow>
                </td>
              </TableRow>
            ))}
          </TableBody>
        </Table> */}
      </DivRow>
      {candidaturas.length === 0 && !loading ? (
        <DivRow className="justify-center">
          <span style={{ fontSize: 24, fontWeight: 600 }}>
            Sem Candidaturas
          </span>
        </DivRow>
      ) : null}
      {/* <DivCenter style={{ marginBottom: 10 }}>
        <Button
          setBackgroundColor={"var(--white)"}
          setColor={"var(--back)"}
          disabled={loading || page.current === 1}
          onClick={() => handlePagination(page.current - 1)}
        >
          <FaArrowLeft size={10} /> Anterior
        </Button>
        <Button
          setBackgroundColor={"var(--white)"}
          setColor={"var(--back)"}
          disabled={loading || page.current === totalPages.current}
          onClick={() => handlePagination(page.current + 1)}
        >
          Próximo <FaArrowRight size={10} />
        </Button>
      </DivCenter> */}
      {loading ? <Spinner /> : <div style={{ height: 80 }}></div>}
    </Content>
  );
};

export default Candidatures;
